/* eslint-disable import/no-anonymous-default-export */
import Axios from "axios";
import constant from "../constant";
import api from "../api";

export default {

  GET_ALL_CATEGORIES: () => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Category.COMMON_CATEGORIES()
        );

        //
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
  GET_CATEGORIES_BY_ID: (id) => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await Axios.get(
          constant.BASE_URL + api.Category.COMMON_CATEGORIES_WITH_ID(id)
        );

        //
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  },
 
};
