const NoPage = () => {
    return (
        <div style={{ padding: '20px', textAlign: 'center' }}>
            <h1>404</h1>
            <p>Page Not Found</p>
            <a  style={{ color: '#007BFF', textDecoration: 'none' }}>
                Go to Home
            </a>
        </div>
    );
};

export default NoPage;
