// import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import About from './Views/about';
import Contact from './Views/contact';
import Home from './Views/home';
import NoPage from './Views/nopage';
import Layout from "./Views";
export default function App() {
  const isLoggedIn = localStorage.getItem('access_token')
  return (
    <BrowserRouter>
      <Layout >
        <Routes>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
         {!isLoggedIn &&  <Route path="contact" element={<Contact />} />}
          <Route path="*" element={<NoPage />} />

        </Routes>
      </Layout>
    </BrowserRouter>
  );
}
